import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Spacer, ComponentWrapper } from 'ascential-horizon'
import { useIdentity } from '../providers/identityContext'
import { windowExists } from '../utils'
import Layout from '../Layout'

function CleanUpPage() {
  const data = useStaticQuery(graphql`
    query {
      contentfulHeader(instanceName: { eq: "Main Header" }) {
        primaryNavigation {
          item {
            label
            url
          }
          subNavigation {
            navItems {
              label
              url
            }
          }
        }
        utilityNavigation {
          label
          url
        }
        userLink
        ctaLabel
        ctaUrl
      }
      contentfulFooter(instanceName: { eq: "Main Footer" }) {
        columns {
          ... on ContentfulNavigation {
            instanceName
            navItems {
              label
              url
            }
          }
          ... on ContentfulTextBlock {
            heading
            copy {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        utilityNavigation {
          navItems {
            label
            url
          }
        }
        socialLabel
        facebookUrl
        twitterUrl
        linkedInUrl
        youTubeUrl
        instagramUrl
      }
    }
  `)

  const { logout } = useIdentity()

  useEffect(() => {
    logout()
  }, [])

  return (
    windowExists && (
      <Layout
        header={data.contentfulHeader}
        footer={data.contentfulFooter}
        slug="/cleanup"
        pageType="/">
        <ComponentWrapper>
          <Spacer space="xlarge">
            <div>
              <h2>Clean up finished here. Redirecting you...</h2>
            </div>
          </Spacer>
        </ComponentWrapper>
      </Layout>
    )
  )
}

export default CleanUpPage
